import React from 'react';
import { Pedclid } from '../../classes/pedido';
import { useDispatch } from 'react-redux';
import { removeFromCart } from './../../store/cart/cart.actions';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './CartItem.scss';

const CartItem: React.FC<Pedclid> = ({ PRECIO_UNITARIO, CANT_PED, DESART, CODART }) => {
    const dispatch = useDispatch();
    const remove = () => dispatch(removeFromCart(CODART));

    return (
        <div className="CartItemContainer">
            <div className="ItemName">{CODART} - {DESART}</div>
            <div className="QtyHandler">
                <Currency className="highlight" value={PRECIO_UNITARIO} /> x 
                <QtySelector 
                    desart={DESART}
                    codart={CODART} 
                    isCheckout={true}
                    price={PRECIO_UNITARIO}
                />
                = <Currency className="TotalItem" value={PRECIO_UNITARIO * CANT_PED} />
                <span 
                    className="removeIcon"
                    onClick={remove}>
                </span>
            </div>
        </div>
    );
};

export default CartItem;