import React from 'react';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './ProductRow.scss';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
const ReactImageZoom = require('react-image-zoom');

export interface IProductRow {
    codart: string,
    desart: string,
    referencias?: string,
    familia?: string,
    subfamilia?: string,
    price: number,
    imgUrl?: string,
    sugerido?: number
    nota?: string;
};

const ProductRow: React.FC<IProductRow> = (articulo: IProductRow) => {
    const { codart, desart, familia, subfamilia, price, imgUrl, sugerido, referencias, nota } = articulo;

    const imgProps = {
        width: 100, 
        height: 80, 
        zoomWidth: 500, 
        scale: 5,
        img: imgUrl ? `http://rthrepuestos.ar/img/${imgUrl}` : './assets/no-disponible.jpg',
        zoomPosition: 'right',
        offset: {
            horizontal: 10,
            vertical: -100
        }
    };

    return (
        <tr className="ProductRow">
            <td><ReactImageZoom {...imgProps} /></td>
            <td className="Desart"><p><span className="Codart">{codart.toUpperCase()}</span></p></td>
            <td className="Desart"><p>{desart.toUpperCase()}</p></td>
            <td className="Categorias"><p>{familia || '-'} <br /> {subfamilia || '-'}</p></td>
            <td className="Referencias">{referencias || ''}</td>
            <td className="Price">
                <p><Currency value={price} /></p>
            </td>
            <td className="Price">
                <p>{sugerido ? <Currency value={sugerido} /> : null}</p>
            </td>
            <td className="QtyCell">
                <QtySelector 
                    desart={desart}
                    codart={codart} 
                    price={price}
                    isCheckout={false}
                />
            </td>
            <td>
                {nota && (
                    <Tooltip title={nota}>
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </td>
        </tr>
    );
};

export default ProductRow;